import { Layout } from 'components/Layout';
import { graphql } from 'gatsby';
import React from 'react';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { CheckListItem } from 'src/components/CheckListItem';
import { Flex } from 'src/components/Flex';
import { MediaSection } from 'src/components/MediaSection';
import { Methods } from 'src/components/Methods/Methods';
import { StreamingIntegrationSection } from 'src/components/Methods/streaming/StreamingIntegrationSection';
import { ClientList } from 'src/components/OurClientsAlt/OurClientsAlt';
import { Paragraph } from 'src/components/Paragraph';
import { Section } from 'src/components/Section';
import SoftwareHero from 'src/components/Software/Hero';
import { ThemeProvider } from 'src/components/ThemeProvider';
import { PrinciplesSection } from 'src/homepage/Principles';

export default function vBulletin({ data }) {
    const sliderList = [
        data.canadavisa.childImageSharp.fluid,
        data.purseforum.childImageSharp.fluid,
        data.talkingpoint.childImageSharp.fluid,
        data.xda.childImageSharp.fluid,
        data.zwanger.childImageSharp.fluid,
    ];

    return (
        <Layout
            pageTitleFull="Upgrade from vBulletin with Audentio"
            siteDescription="Migrate from vBulletin and bring your community to the industry standard forum software. Contact us now."
            contactForm
        >
            <SoftwareHero
                title="Upgrade from vBulletin with modern tech"
                carousel={sliderList}
            >
                <Box pt="spacing" as="ul">
                    {[
                        {
                            title:
                                'Strengthen and modernize your tech stack with XenForo and our tools',
                        },
                        {
                            title:
                                'Increase engagement and revenue opportunities strategically',
                        },
                        {
                            title:
                                'Partner with community experts that have worked within the vBulletin and XenForo frameworks for years',
                        },
                        {
                            title:
                                'Build upon your community’s assets to help it thrive',
                        },
                        {
                            title:
                                'Keep your community moving forward with a dedicated team supporting the community',
                        },
                    ].map(highlight => (
                        <CheckListItem
                            key={highlight.title}
                            title={highlight.title}
                            as="li"
                        />
                    ))}
                </Box>
            </SoftwareHero>

            <MediaSection
                id="strategy"
                title="Have the community you deserve with our tried-and-true strategy."
                flipped
                bg="primaryBackground"
                customMedia={
                    <Flex>
                        <Paragraph color="bodyText" maxW={525}>
                            Our team of community experts, including designers,
                            developers, and community managers, can help your
                            community grow with an emphasis on better engagement
                            and more monetization opportunities.
                        </Paragraph>
                    </Flex>
                }
            />

            <PrinciplesSection />

            <Methods
                bg={{
                    _: 'primaryBackground',
                    lg: 'secondaryBackground',
                }}
                pt={{
                    _: null,
                    lg: 0,
                }}
            />

            <ThemeProvider defaultMode="dark">
                <MediaSection
                    id="streaming"
                    title="Engage directly with your audience by streaming"
                    image={data.streaminterface.childImageSharp.fluid}
                    bg="primaryBackground"
                    alt="Live streaming event in progress with host talking and audience engaging in the chat box."
                >
                    <Paragraph color="bodyText">
                        Streaming from your own platform gives you more control
                        over your brand; integration with your community
                        features; monetization without percentage cuts; and the
                        ability to keep engagement on your platform.
                    </Paragraph>
                    <Box pt="spacing-lg">
                        <Button href="/contact?source=streaming" fill="white">
                            learn more about streaming
                        </Button>
                    </Box>
                </MediaSection>
            </ThemeProvider>

            {/* This isn't really a media section, but the component allows for a custom content section, and helps us stick with a consistent layout and structure */}
            <MediaSection
                id="why-xenforo"
                title="Why do we recommend XenForo for your community?"
                flipped
                bg="primaryBackground"
                alt="Client examples showing a before and after of a community being upgraded from vBulletin to XenForo."
                customMedia={
                    <Flex>
                        <Paragraph color="bodyText" maxW={525}>
                            There are a lot of community platforms out there,
                            but we build on XenForo. Why? Because it is one of
                            the strongest development frameworks. It’s
                            well-documented and has a large third-party
                            marketplace for integrations. We could go on, and
                            actually we do in an article where we detail all the
                            reasons we love XenForo.
                        </Paragraph>
                    </Flex>
                }
            />

            <MediaSection
                id="migration"
                title="Avoid the headache and let us handle the data migration"
                videoPoster="/videos/vbmigrationposter.jpg"
                video="/videos/vbmigration.mp4"
                videoId="vbmigration"
            >
                <Paragraph color="bodyText">
                    We’ve successfully migrated some of the largest communities
                    to XenForo, handling server nuances and taking care in every
                    detail.
                </Paragraph>
                <Box pt="spacing" as="ul">
                    {[
                        {
                            title: 'All data ported correctly to XenForo',
                        },
                        {
                            title: 'Add-ons and themes are replaced or rebuilt',
                        },
                        {
                            title: 'URLs redirected correctly',
                        },
                    ].map(highlight => (
                        <CheckListItem
                            key={highlight.title}
                            title={highlight.title}
                            headingStyle={{
                                fontWeight: 'normal',
                            }}
                            as="li"
                        />
                    ))}
                </Box>
                <Box pt="spacing-lg">
                    <Button href="/contact?source=migration" fill="black">
                        contact us
                    </Button>
                </Box>
            </MediaSection>

            <Section vertical="md" pt={[0, 0, 0]} wrap bg="secondaryBackground">
                <ClientList
                    title="vBulletin Communities We've Upgraded"
                    clients={[
                        { name: 'IGN', image: data.ign },
                        { name: 'cPanel', image: data.cpanel },
                        { name: 'Yahoo!', image: data.yahoo },
                        { name: 'MacRumors', image: data.macrumors },
                        { name: "Tom's hardware", image: data.tomshardware },
                    ]}
                    borderTop="1px"
                    borderColor="border"
                    pt={['spacing-lg', '40px', 'spacing-xl']}
                />
            </Section>

            <MediaSection
                id="revenue"
                title="Expand your revenue streams"
                image={data.revenuestreams.childImageSharp.fluid}
                bg="primaryBackground"
                alt="Different methods of monetization that include subscriptions, ads, donations, sponsorships, and stream event tickets."
            >
                Monetize in ways that fit your community and goals with
                customizable subscription tiers, donations, event ticket sales,
                web and mobile ads, and sponsors.
            </MediaSection>

            <StreamingIntegrationSection
                id="membership"
                title="Give your community a native app for easier engagement"
            />
        </Layout>
    );
}

export const query = graphql`
    query VbQuery {
        canadavisa: file(
            relativePath: { eq: "images/vbsoftware/slider/canadavisa.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        purseforum: file(
            relativePath: { eq: "images/vbsoftware/slider/purseforum.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        talkingpoint: file(
            relativePath: { eq: "images/vbsoftware/slider/talking-point.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        xda: file(relativePath: { eq: "images/vbsoftware/slider/xda.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        zwanger: file(
            relativePath: { eq: "images/vbsoftware/slider/zwanger.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        revenuestreams: file(
            relativePath: { eq: "images/xfsoftware/revenue-streams.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        streaminterface: file(
            relativePath: { eq: "images/xfsoftware/stream-interface.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        ign: file(relativePath: { eq: "images/clients/ign.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        tomshardware: file(
            relativePath: { eq: "images/clients/toms-hardware.png" }
        ) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        macrumors: file(relativePath: { eq: "images/clients/macrumors.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        yahoo: file(relativePath: { eq: "images/clients/yahoo-sports.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        cpanel: file(relativePath: { eq: "images/clients/cpanel.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
